import React, { Component, useState, useRef, useEffect } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { Checkbox, Container, Grid, Header, Icon, Image, Menu, Modal, Button, Input, Form } from 'semantic-ui-react';
import axios from 'axios';
const ModalLogin = (props) => {
  const { t, i18n } = useTranslation();
  const { errors } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')


  // Cleaning the form when opening it
  useEffect( () => {
    if( props.open ){
      setEmail(''); setPassword('')
    }
  },[props.open])

  return <Modal
          closeIcon
          open={props.open}
          onClose={() => props.onClose()}   
          size='small'>
            <Header content={t('returningCustomers')} />
            <Modal.Content>


            <Grid.Column
                mobile={16}
                tablet={16}
                computer={8}
                style={{ height: '100%' }}
              >

                  <Input
                    name='emailExisting'
                    type='email'
                    label={{ basic: true, content: 'Email Address *' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email'
                    fluid
                    error={errors.emailExisting != null}
                  />
                  {errors.emailExisting != null && (
                    <div
                      className='ui pointing red basic label'
                      style={{ marginBottom: '10px' }}
                    >
                      {errors.emailExisting}
                    </div>
                  )}
                  <br />
                  <Input
                    name='passwordExisting'
                    type='password'
                    label={{ basic: true, content: 'Password *' }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fluid
                    placeholder='Password'
                    error={errors.emailExisting != null}
                  />
                  {errors.passwordExisting != null && (
                    <div
                      className='ui pointing red basic label'
                      style={{ marginBottom: '10px' }}
                    >
                      {errors.passwordExisting}
                    </div>
                  )}
                
              </Grid.Column>


            </Modal.Content>
            <Modal.Actions>
              <Button type='button' color='black' onClick={(e) => props.onClick(e, email, password, e)}>
                {t('login')}
              </Button>
            </Modal.Actions>
          </Modal>
}

const StoreFrontHeader = (props) => {  
  const [ showLogin, setShowLogin ] = useState(false)  
  const { t, i18n } = useTranslation();

  return (<>
            <Grid.Row style={{ padding: 0 }}>
              <div className={`login-header`}>
               <div className={`${props.showNewCustomer?'':'invisible'}`}
                onClick={ () => setShowLogin(true) }>SIGN IN / MANAGE ACCOUNT
                </div>
              </div>
            </Grid.Row>

            <ModalLogin 
              open={showLogin && props.showNewCustomer}
              onClose={() => setShowLogin(false)}
              emailExisting={props.emailExisting}
              onClick={async (e, email, password) => 
                {
                  const res = await props.onLoginClick(e, email, password)
                  // When login successful
                  if( res ) setShowLogin(false)
                }
              }
              errors={props.errors}
              />

            <Grid.Row style={{ padding: 0 }}>
            <div style={props.style.container}>
              <div style={{ float: 'left' }}>
                <p>
                  <span style={{ fontSize: '24px', marginLeft: '30px' }}>
                    {t('subscribe')}
                  </span>
                </p>
              </div>
              <div style={{ textAlign: 'right', float: 'right' }}>
                <p>
                  <span
                    style={{
                      marginBottom: '-80px',
                      fontSize: '16px',
                      marginRight: '30px',
                    }}
                  >
                    {props.publisherName}
                  </span>
                </p>
              </div>
            </div>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: 0 }}>
            <div style={props.style.logocontainer}>                
              <Image
                src={props.logo}
                style={{ height: '80px' }}
                centered
              />
            </div>
          </Grid.Row>
          <Grid.Row style={props.style.magbackground}>
            <Grid.Column width={7} className='storefront--thumbImg'>
              {props.sampleEdition ? (
                <a
                  href={props.sampleEdition}
                  className='clickable'
                  target='_blank'
                  data-toggle='tooltip'
                  title='Click to see the sample edition'
                  rel='noreferer noopener'
                >
                  <Image
                    src={props.thumbImageName}
                    style={{
                      maxHeight: '390px',
                      boxShadow: '-1px 2px 12px 0px #000000',
                    }}
                  />
                  <div className='cursor-gif'></div>
                </a>
              ) : (
                <Image
                  src={props.thumbImageName}
                  style={{
                    maxHeight: '390px',
                    boxShadow: '-1px 2px 12px 0px #000000',
                  }}
                />
              )}
            </Grid.Column>
            <Grid.Column width={7} className='storefront--header'>
              <Header as='h1'>
                {props.publication.publicationName}
              </Header>
              <p>{props.publication.publicationInfo}</p>
            </Grid.Column>
          </Grid.Row>
        </>)
}

const StoreFrontFooter = (props) => {
  const { t, i18n } = useTranslation();
  return (            
  <Grid.Row style={props.style.menu}>
    <Menu inverted={true} secondary={true} size='large' borderless>
      <Container style={{ flexWrap: 'wrap'}}>
        {props.tel !== undefined && props.tel !== '' ? (
          <Menu.Item
            position='left'
            name='tel'
            href={'tel:' + props.tel}
            className={"footer-menu-item-first footer-menu-item"}
          >
            <p>{props.tel}</p>
          </Menu.Item>
        ) : null}
        {props.mainContactEmail !== undefined &&
        props.mainContactEmail !== '' ? (
          <Menu.Item
            name='infoemail'
            href={'mailto:' + props.publication.emailAddress}
            target='_blank'
            className={"footer-menu-item"}
          >
            <p>{props.publication.emailAddress}</p>
          </Menu.Item>
        ) : null}
        <Menu.Item
          position='right'
          name='privacy'
          href='/privacy'
          target='_blank'
          className={"footer-menu-item"}
        >
          <p>{t('privacyPolicy')}</p>
        </Menu.Item>

        {props.publication.facebook !== undefined &&
        props.publication.facebook !== '' &&
        props.publication.facebook !== null ? (
          <Menu.Item
            position='right'
            name='login'
            href={props.publication.facebook}
            target='_blank'
            className={"footer-menu-item"}
          >
            <p><Icon name='facebook' size='large' /></p>
          </Menu.Item>
        ) : null}
        {props.publication.twitter !== undefined &&
        props.publication.twitter !== '' &&
        props.publication.twitter !== null ? (
          <Menu.Item
            name='login'
            href={props.publication.twitter}
            target='_blank'
            className={"footer-menu-item"}
          >
            <p><Icon name='twitter' size='large' /></p>
          </Menu.Item>
        ) : null}
        {props.publication.youtube !== undefined &&
        props.publication.youtube !== '' &&
        props.publication.youtube !== null ? (
          <Menu.Item
            name='login'
            href={props.publication.youtube}
            target='_blank'
            className={"footer-menu-item"}
          >
            <p><Icon name='youtube' size='large' /></p>
          </Menu.Item>
        ) : null}
        {props.publication.instagram !== undefined &&
        props.publication.instagram !== '' &&
        props.publication.instagram !== null ? (
          <Menu.Item
            name='login'
            href={props.publication.instagram}
            target='_blank'
            className={"footer-menu-item"}
          >
            <p><Icon name='instagram' size='large' /></p>
          </Menu.Item>
        ) : null}
      </Container>
    </Menu>
    <div
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '0px',
      }}
    >
      <div
        style={{
          textAlign: 'centre',
          fontSize: '15px',
          color: '#fff',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center'
        }}
      >
        {props.publication.publicationName
          ? props.publication.publicationName + ' | '
          : ''}
        {props.publisherName
          ? props.publisherName + ' | '
          : ''}
        {props.addressOne ? props.addressOne + ' | ' : ''}
        {props.addressTwo ? props.addressTwo + ' | ' : ''}
        {props.addressThree
          ? props.addressThree + ' | '
          : ''}
        {props.postZip}
      </div>
      <br />
      <br />
      <p
        style={{
          textAlign: 'center',
          fontSize: '12px',
          color: '#fff',
          marginBottom: '20px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        Powered by MagStand, part of{' '}
        <a href='https://detl.co.uk'>
          Digital Edition Technology Ltd.
        </a>
      </p>
    </div>
  </Grid.Row>)
}

const ModalProcessingFailed = (props) => {
  return <Modal open={props.open} basic
            onClose={() => props.onClose({ modalProcessingFailed: false})}
            size='small'>
            <Header content='Payment' />
            <Modal.Content>
              Sorry your payment has been declined. Please try with another payment method
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => props.onClose({ modalProcessingFailed: false})} inverted>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
}

const StoreFrontOptIn = (props) => {
  const { t } = useTranslation()
  const [ offers, setOffers ] = useState({
    pubOffers: false, pubOffersPost: false, pubOffersTel: false, pubOffersSMS: false,
    partnerOffers: false, partnerOffersPost: false, partnerOffersTel: false, partnerOffersSMS: false
  })

  const onChange = (key) => {
    const offersLocal = { ...offers }
    offersLocal[key] = !offersLocal[key] 
    setOffers({...offersLocal})
    props.onChange( offersLocal )
  }

  // Mandatory
  if( props.publication == null)
    return <></>



  // Proceed
  const { publicationName } = props.publication;
  return <div className='storefront__opt'>
          <p>
            <Trans i18nKey='contactWithOffers' publicationName={publicationName}>
            {{publicationName: publicationName}}{' '} 
              would like to contact you with offers and opportunities.              
            </Trans>
            {t('notPreferReceive')}
          </p>
          <Checkbox name='pubOffers' label={t('optOutEmailOffers')}
            onChange={() => onChange('pubOffers')}
            checked={offers.pubOffers}
            style={{ marginRight: '20px' }}
          />
          <Checkbox name='pubOffersPost' label={t('optOutPostOffers')}
            onChange={() => onChange('pubOffersPost') }
            checked={offers.pubOffersPost}
            style={{ marginRight: '20px' }}
          />
          <Checkbox
            name='pubOffersTel'
            label={t('optOutPhoneOffers')}
            onChange={() => onChange('pubOffersTel') }
            checked={offers.pubOffersTel}
            style={{ marginRight: '15px' }}
          />

          <Checkbox
            name='pubOffersSMS'
            label={t('optOutSMSOffers')}
            onChange={() => onChange('pubOffersSMS') }
            checked={offers.pubOffersSMS}
          />
          <p style={{ marginTop: '10px' }}>
            {t('specialOfferInstruction1')} <br />
            {t('specialOfferInstruction2')}
          </p>
          <Checkbox
            name='partnerOffers'
            label={t('optOutEmailOffers')}
            style={{ marginRight: '20px' }}
            onChange={() => onChange('partnerOffers') }
            checked={offers.partnerOffers}
          />
          <Checkbox
            name='partnerOffersPost'
            label={t('optOutPostOffers')}
            onChange={() => onChange('partnerOffersPost') }            
            checked={offers.partnerOffersPost}
            style={{ marginRight: '20px' }}
          />
          <Checkbox
            name='partnerOffersTel'
            label={t('optOutPhoneOffers')}
            onChange={() => onChange('partnerOffersTel') }
            checked={offers.partnerOffersTel}
            style={{ marginRight: '15px' }}
          />
          <Checkbox
            name='PartnerOffersSMS'
            label={t('optOutSMSOffers')}
            onChange={() => onChange('partnerOffersSMS') }          
            checked={offers.partnerOffersSMS}
          />
        </div>
}

const CustomQuestionContainer = (props) => {
  const yesNooptions = [
    { key: 'yes', text: 'Yes', value: 'Yes', },
    { key: 'no', text: 'No', value: 'No', },
  ];
  const { t, i18n } = useTranslation();

  const [customQuestionAns, setCustomQuestion] = useState({
    customQuestionAans: '',
    customQuestionBans: '',
    customQuestionCans: '',
    customQuestionDans: '',
    customQuestionEans: '',
  })

  const handleQuestionAChange = (e, { value }) => {
    setCustomQuestion({ ...customQuestionAns, customQuestionAans: value });
    props.onChange({ ...customQuestionAns, customQuestionAans: value })
  };

  const handleQuestionBChange = (e, { value }) => {
    setCustomQuestion({ ...customQuestionAns, customQuestionBans: value });
    props.onChange({ ...customQuestionAns, customQuestionBans: value })
  };

  // Generic Change Handler
  const onChange = (e) => {
    setCustomQuestion({ ...customQuestionAns, [e.target.name]: e.target.value });
    props.onChange({ ...customQuestionAns, [e.target.name]: e.target.value })
  };

  const { customQuestionA, customQuestionB, 
      customQuestionC, customQuestionD, customQuestionE} = props.publication
  return <>
            {customQuestionA != null && customQuestionA.length > 0? 
              <Grid.Row>
                <Grid.Column>
                  <div style={{ paddingLeft: '25px', paddingRight: '25px' }}>
                    <Form.Select
                      fluid options={yesNooptions} size='large' selection
                      onChange={handleQuestionAChange}
                      name='customQuestionAans' placeholder={t('pleaseChoose')}
                      label={customQuestionA}            
                      value={customQuestionAns.customQuestionAans}
                      error={customQuestionAns.customQuestionAans === 'No'}
                    />
                    {customQuestionAns.customQuestionAans === 'No' &&
                    <div style={{color: '#9f3a38'}}>Sorry, you do not qualify for a free subscripiton</div>}
                  </div>
                </Grid.Column>
              </Grid.Row>
            : null}

            {customQuestionB != null && customQuestionB.length > 0? 
              <Grid.Row>
                <Grid.Column>
                  <div style={{ paddingLeft: '25px', paddingRight: '25px' }}>
                    <Form.Select fluid options={yesNooptions} size='large' selection
                      name='customQuestionBans' placeholder={t('pleaseChoose')}
                      onChange={handleQuestionBChange}            
                      label={customQuestionB}                                
                      value={customQuestionAns.customQuestionBans}
                      error={customQuestionAns.customQuestionBans === 'No'}
                    />
                  {customQuestionAns.customQuestionBans === 'No' &&
                    <div>Sorry, you do not qualify for a free subscripiton</div>}
                  </div>
                </Grid.Column>
              </Grid.Row>
            : null}

            {customQuestionC != null && customQuestionC.length > 0? 
              <Grid.Row>
                <Grid.Column>
                  <div style={{ paddingLeft: '25px', paddingRight: '25px' }}>
                    <Input name='customQuestionCans' fluid
                      label={{ basic: true, content: customQuestionC, }}
                      value={customQuestionAns.customQuestionCans}
                      onChange={onChange}            
                      error={customQuestionAns.customQuestionCans === 'No'}
                    />
                  {customQuestionAns.customQuestionCans === 'No' &&
                  <div style={{color: '#9f3a38'}}>Sorry, you do not qualify for a free subscripiton</div>}                  </div>
                </Grid.Column>
              </Grid.Row>
            : null}

            {customQuestionD != null && customQuestionD.length > 0? 
              <Grid.Row>
                <Grid.Column>
                  <div style={{ paddingLeft: '25px', paddingRight: '25px' }}>
                    <Input fluid name='customQuestionDans'
                      label={{ basic: true, content: customQuestionD, }}
                      value={customQuestionAns.customQuestionDans}
                      onChange={onChange}
                      error={customQuestionAns.customQuestionDans === 'No'}
                    />
                  {customQuestionAns.customQuestionDans === 'No' &&
                  <div style={{color: '#9f3a38'}}>Sorry, you do not qualify for a free subscripiton</div>}                  </div>
                </Grid.Column>
              </Grid.Row>
            : null}

            {customQuestionE != null && customQuestionE.length > 0? 
              <Grid.Row>
                <Grid.Column>
                  <div style={{ paddingLeft: '25px', paddingRight: '25px' }}>
                    <Input fluid name='customQuestionEans'
                      label={{ basic: true, content: customQuestionE, }}
                      value={customQuestionAns.customQuestionEans}
                      onChange={onChange}
                      error={customQuestionAns.customQuestionEans === 'No'}
                    />
                    {customQuestionAns.customQuestionEans === 'No' &&
                    <div style={{color: '#9f3a38'}}>Sorry, you do not qualify for a free subscripiton</div>}                  </div>
                </Grid.Column>
              </Grid.Row>
            : null}
          </>
}

const PostalCodeLookup = (props) => {
  const [pattern, setPattern] = useState('')
  const [error, setError] = useState(false)
  const [addressList, setAddressList] = useState([])

  const { t, i18n } = useTranslation();
  const onClick = async (e) => {
    e.preventDefault();
    try{
      const response = await axios.get(`/api/thirdparty/postcodelookup/${pattern}`)          

      const processedArray = []
      for( const [idx, item] of response.data.addresses.entries() ){
        processedArray.push({
          items: idx, text: item.split(','), value: item,
        });
      }
      setAddressList(processedArray)
    }catch(e){
      setError( true )
    }
  };

  return <div>
            <Input
            label={{ basic: true, content: t('postcodeLookup') }}
            action={
              <Button color='black' icon='search' type='button' content={t('lookup')}
                onClick={(e) => onClick(e)} />
            }
            value={pattern} name='billingPCLookup'
            onChange={e => setPattern(e.target.value)}
            error={error} fluid
            />
            <br />
            {addressList.length > 0 &&
            <>
              <Form.Select
                options={addressList}
                onChange={(e, { value, text }) => props.onSelected(e, {value,text})}
                fluid
                size='large'
                selection
                placeholder={t('selectAddr')}
                name='deliveryPostcode'
                error={error}
              />
              <br />
            </>
            }
          </div>
}

export { StoreFrontFooter, StoreFrontHeader, 
    ModalProcessingFailed, ModalLogin, StoreFrontOptIn, 
    CustomQuestionContainer, PostalCodeLookup}